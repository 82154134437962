import { getDisabledReasonTooltip } from '../../../../organisms/share-quote-button'
import { useQuoteEditorState } from '../backoffice-quote-editor-v2/quote-editor-v2-state'

export function getDisabledPaymentReason({
  changes,
  state,
  nonDimensionalDataAfter,
}: {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  state: ReturnType<typeof useQuoteEditorState>['before']
  nonDimensionalDataAfter: ReturnType<typeof useQuoteEditorState>['nonDimensionalDataAfter']
}): string | null {
  if (!nonDimensionalDataAfter) return null

  const { canGenerateFinalAgreedTerms } = nonDimensionalDataAfter
  const hasChanges = Object.keys(changes).length > 0

  switch (true) {
    case nonDimensionalDataAfter.quoteSharingStatus.type === 'disabled':
      return getDisabledReasonTooltip(nonDimensionalDataAfter.quoteSharingStatus)
    case canGenerateFinalAgreedTerms.type === 'cannot-generate':
      return canGenerateFinalAgreedTerms.reason
    case hasChanges:
      return 'Il faut d’abord valider le devis pour finaliser la souscription'
    case state?.rcdaSelected && !state?.documentsValidated:
      return 'Il faut que les documents soient validés pour finaliser la souscription'
    default:
      return null
  }
}
